.destacado {
  padding: $space-3 0;

  @media screen and (max-width: 480px) {
    padding: $space-2 0;
  }

  &__content {
  }

  &__items {
    display: grid;
    grid-template-columns: repeat(4, minmax(0, 1fr));
    gap: $space-3;
    @media screen and (max-width: 767px) {
      grid-template-columns: repeat(2, minmax(0, 1fr));
      gap: $space-2 $space-3;
    }
    @media screen and (max-width: 575px) {
      grid-template-columns: 1fr;
      gap: $space-1;
    }
  }

  &__item {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
  }

  &__item-icono {
    img {
      width: 112px;
      height: auto;
    }
  }

  &__item-titulo {
    font-family: $typo-secondary;
    font-size: $font-size-bigger;
    font-weight: $font-weight-bold;
    color: $color-primary;
  }
  &__item-descripcion {
  }
}

.caracteristicas {
  padding: $space-3 0;
  background-color: $color-background-2;
  &__items {
    // display: grid;
    // grid-template-columns: repeat(4, minmax(0, 1fr));
    // gap: $space-1;
  }

  &__item {
    background-color: $color-background;
    border-radius: $border-radius;
    padding: $space-1;
    margin: 0 $space-01;
    display: grid !important;
    grid-template-columns: max-content 1fr;
    gap: $space-1;
  }

  &__info {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  &__icono {
    img {
      width: 100%;
      height: auto;
      max-width: 84px;
    }
  }

  &__dato {
    font-size: $header-2;
    line-height: 1;
    font-weight: $font-weight-medium;
    color: $color-secondary;
  }

  &__titulo {
    font-size: $header-3;
    font-weight: $font-weight-medium;
    color: $color-primary;
    margin-bottom: $space-1;
  }

  &__descripcion {
  }
}
