.cursor-pointer {
  cursor: pointer;
}

// Utilitats de colors i backgrounds
// ------------------------------------------------
$colors: (
  "primary" $color-primary,
  "primary-light" $color-primary-light,
  "primary-dark" $color-primary-dark,
  "secondary" $color-secondary,
  "secondary-light" $color-secondary-light,
  "secondary-dark" $color-secondary-dark,
  "accent" $color-accent,
  "accent-light" $color-accent-light,
  "accent-dark" $color-accent-dark,
  "white" $color-white,
  "black" $color-black,
  "dark" $color-dark,
  "grey-1" $color-grey-1,
  "grey-2" $color-grey-2,
  "grey-3" $color-grey-3,
  "grey-4" $color-grey-4,
  "grey-5" $color-grey-5,
  "grey-6" $color-grey-6
);

@each $name, $color in $colors {
  .bg-#{$name} {
    background: $color !important;
  }
}

@each $name, $color in $colors {
  .color-#{$name} {
    color: $color !important;
  }
}

// Mides funcionals per a espais
// ------------------------------------------------
$sizes: (
  "top" "t" "0" $space-0,
  "top" "t" "05" $space-05,
  "top" "t" "1" $space-1,
  "top" "t" "2" $space-2,
  "top" "t" "3" $space-3,
  "top" "t" "4" $space-4,
  "top" "t" "5" $space-5,
  "bottom" "b" "0" $space-0,
  "bottom" "b" "05" $space-05,
  "bottom" "b" "1" $space-1,
  "bottom" "b" "2" $space-2,
  "bottom" "b" "3" $space-3,
  "bottom" "b" "4" $space-4,
  "bottom" "b" "5" $space-5
);

// loop top i bottom
@each $direction, $d, $name, $size in $sizes {
  .pad-#{$d}-#{$name} {
    padding-#{$direction}: $size !important;
    @media screen and (max-width: 575px) {
      @if ($space-1) or ($space-2) {
        padding-#{$direction}: calc($size / 1.5) !important;
      } @else {
        padding-#{$direction}: $size !important;
      }
    }
  }
  .mar-#{$d}-#{$name} {
    margin-#{$direction}: $size !important;
    @media screen and (max-width: 575px) {
      @if ($space-1) or ($space-2) {
        margin-#{$direction}: calc($size / 1.5) !important;
      } @else {
        margin-#{$direction}: $size !important;
      }
    }
  }
}

// loop top i bottom en y
@each $direction, $d, $name, $size in $sizes {
  .pad-#{$name} {
    padding: $size 0 !important;
    @media screen and (max-width: 575px) {
      @if ($space-1) or ($space-2) {
        padding: calc($size / 1.5) 0 !important;
      } @else {
        padding: $size 0 !important;
      }
    }
  }
  .mar-#{$name} {
    margin: $size 0 !important;
    @media screen and (max-width: 575px) {
      @if ($space-1) or ($space-2) {
        margin: calc($size / 1.5) 0 !important;
      } @else {
        margin: $size 0 !important;
      }
    }
  }
}

.img-rounded {
  border-radius: $border-radius;
}
.img-rounded-shadow {
  border-radius: $border-radius;
  box-shadow: $box-shadow;
}

.lista-tags {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 0.5rem;
  &--va {
    @media screen and (max-width: 575px) {
      justify-content: center;
      .lista-tags__item {
        background-color: $color-secondary;
        color: $color-white;
        justify-content: center;
        a {
          color: $color-white;
        }
      }
    }
  }

  &__item {
    margin-right: $font-size-small;
    margin-bottom: 0.5rem;
    background-color: $color-white;
    padding: 2px 6px;
    border-radius: 3px;
    font-size: $font-size-small;
    a {
      color: $color-grey-2;
    }
    &:nth-last-child(1) {
      margin-right: 0;
    }
  }
}

// .container--max {
//   max-width: 1460px;
// }

// .container--max {
//   max-width: 1400px;
//   margin: 0 auto;
//   padding-left: 20px;
//   padding-right: 20px;
//   @media screen and (min-width: 768px) and (max-width: 1440px) {
//     padding-left: 40px;
//     padding-right: 40px;
//   }
// }

.container--min {
  max-width: 870px;
}

.text-center {
  text-align: center;
}

.oculto {
  visibility: hidden;
  opacity: 0;
  transition: $trans;
}
.visible {
  visibility: visible;
  opacity: 1;
  transition: $trans;
}

.d-block {
  display: block;
}

.d-none {
  display: none;
  visibility: hidden;
  transition: $trans;
}

.font-bold {
  font-weight: $font-weight-bold;
}

.desktop-991 {
  @media screen and (max-width: 991px) {
    display: none;
    visibility: hidden;
  }
}

.mobile-991 {
  @media screen and (min-width: 992px) {
    display: none;
    visibility: hidden;
  }
}

.contacto-block {
  margin-top: $space-1;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
  gap: $space-01;
  @media screen and (max-width: 575px) {
    flex-direction: column;
  }

  &__item {
    @media screen and (max-width: 575px) {
      width: 100%;
    }
    .boton {
      width: 226px;
      @media screen and (max-width: 575px) {
        width: 100%;
      }
    }
  }

  &__item--tel {
  }

  &__item--whats {
  }

  &__item--contacto {
  }
}
