.nav-primary {
  &__items {
    list-style: none;
    padding-left: 0;
    margin-bottom: 0;
    display: flex;
    align-items: center;
    & > li.menu-item {
      a {
        color: $color-black;
        font-size: $font-size;
        font-family: $typo-secondary;
        text-decoration: none;
        padding: $font-size-smaller $font-size-small;
        @media screen and (max-width: 1400px) {
          padding-left: 14px;
          padding-right: 14px;
        }
        &:hover {
          color: $color-primary;
        }
        @media screen and (max-width: 1320px) {
          font-size: 0.95rem;
          padding-left: $font-size-smaller;
          padding-right: $font-size-smaller;
        }
      }
    }
  }
}

.header__nav--right {
  .nav-primary ul li.menu-item {
    a {
      @include button;
      background-color: $color-primary;
      border-color: $color-primary;
      color: $color-white;
      &:hover {
        background-color: $color-primary;
        border-color: $color-primary;
        color: $color-white;
        box-shadow: $box-shadow-small;
        transform: translateY(-2px);
        transition: $trans;
      }
      @media screen and (max-width: 1010px) {
        padding: 10px 16px;
      }
    }
  }
}

#offcanvasMenu {
  width: 100vw;
  border: none;
}

.offcanvas {
  &-body {
    background-color: $color-background;
    padding-top: 0.5rem;
    button {
      display: flex;
      margin-left: auto;
      margin-top: 0.5rem;
      border: none;
      background: none;
      font-size: 3rem;
      line-height: 0;

      i {
        color: $color-black;
        &:hover {
          color: $color-primary;
        }
      }
    }
  }

  &__navbar {
    display: flex;
    flex-direction: column;
    margin-top: 1.4rem;
    align-items: center;

    @media screen and (max-width: 575px) {
      margin-top: 0;
      padding-top: 0;
    }
  }

  &__menu {
    width: 100%;
    display: flex;
    justify-content: center;
    text-align: center;
  }
  &__nav {
    justify-content: flex-start;
    padding-left: 0;
    margin-bottom: 0;
    list-style: none;
    font-size: 1rem;
    li.menu-item {
      margin-bottom: 0.6rem;
      @media screen and (max-width: 575px) {
        margin-bottom: 0.3rem;
      }
      & > a {
        font-size: $header-2-res;
        font-weight: $font-weight-bold;
        text-decoration: none;
        color: $color-black;
        &:hover {
          color: $color-primary;
        }
        // @media screen and (max-width: 767px) {
        //   font-size: $header-3;
        // }
      }
      ul.sub-menu {
        list-style: none;
        display: flex;
        justify-content: center;
        padding-left: 0;
        flex-wrap: wrap;
        li {
          margin: 0 5px 5px 0px;
          a {
            background-color: $color-black;
            color: $color-primary !important;
            font-size: 1.5rem;
            padding: 4px 8px;
            border-radius: $border-radius-min;
            display: inline-block;
            font-weight: $font-weight-medium;
            transition: $trans;
            &:hover {
              background-color: $color-black !important;
              color: $color-primary !important;
              transition: $trans;
            }
            @media screen and (max-width: 767px) {
              font-size: 1.25rem;
              transition: $trans;
            }
            @media screen and (max-width: 480px) {
              font-size: 1.125rem;
              transition: $trans;
            }
          }
        }
      }
    }
  }

  &__rs {
    margin: $space-01 0;
    .widget-footer__title,
    p {
      display: none;
      visibility: hidden;
    }
    display: flex;
    justify-content: center;
    margin-bottom: $space-1;
    ul {
      margin-left: 0 !important;
      margin-top: 0 !important;
    }
    .social-icon {
      font-size: 28px !important;
      color: $color-white !important;
      background-color: $color-black !important;
      padding: 3px !important;

      &:hover {
        color: $color-white !important;
        background-color: $color-primary !important;
      }
    }
  }
  &__logo {
    img,
    svg {
      display: flex;
      width: 240px;
      margin: 1rem auto 0 auto;
      transition: $trans;
      @media screen and (max-width: 575px) {
        width: 180px;
        transition: $trans;
      }
    }
  }
}

.widget--legal {
  .widget__title {
    font-size: $header-3;
    font-weight: $font-weight-bold;
    color: $color-primary;
  }
  #menu-menulegal {
    list-style: none;
    padding-left: 0;
    margin-bottom: 0;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: $space-05;
    & > li.menu-item {
      a {
        text-decoration: none;
        line-height: 1.5;

        color: $color-black;
        &:hover {
          color: $color-primary;
        }
      }
    }
    @media screen and (max-width: 767px) {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: center;
      align-items: center;
      gap: $space-01;
      li a {
        @include button;
        padding: 0.25rem 0.5rem;
        background: $color-primary;
        color: $color-black !important;
        border-color: $color-primary;
        transition: $trans;
        &:hover {
          background: darken($color-primary, 5%);
          border-color: darken($color-primary, 5%);
          color: $color-black;
          box-shadow: $box-shadow-small;
          transform: translateY(-2px);
          transition: $trans;
        }
      }
    }
  }
}

#menu-menulegal {
  list-style: none;
  padding-left: 0;
  margin-bottom: 0;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: $space-01;
  & > li.menu-item {
    position: relative;
    padding-left: $space-05;
    &::before {
      position: absolute;
      left: -12px;
      top: 3px;
      display: inline-block;
      content: "";
      color: $color-grey-4;
      vertical-align: -0.125em;
      background-image: url("data:image/svg+xml,<svg viewBox='0 0 16 16' fill='%23b6b6b6' xmlns='http://www.w3.org/2000/svg'><path fill-rule='evenodd' d='M13.854 2.146a.5.5 0 0 1 0 .708l-11 11a.5.5 0 0 1-.708-.708l11-11a.5.5 0 0 1 .708 0Z' clip-rule='evenodd'/></svg>");
      background-repeat: no-repeat;
      background-size: 1rem 1rem;
      width: 1rem;
      height: 1rem;
      // filter: brightness(0) invert(1);
    }
    a {
      font-size: $font-size-small;
      text-decoration: none;
      line-height: 1.5;
      color: $color-grey-4;
      &:hover {
        color: $color-secondary;
      }
    }
  }
}

#menu-menufooter {
  list-style: none;
  padding-left: 0;
  margin-bottom: 0;
}

.nav-overflow {
  padding: $font-size-smaller 0;
  background-color: $color-grey-6;
  .container {
    @media screen and (max-width: 991px) {
      width: 100%;
      max-width: 100%;
      padding: 0;
    }
  }
}

.nav-servicios {
  &__items {
    display: flex;
    list-style: none;
    padding-left: 0;
    margin-bottom: 0;
    gap: 10px;
    flex-wrap: wrap;
    @media screen and (max-width: 991px) {
      overflow-x: scroll;
      flex-wrap: nowrap;
    }
    .menu-item {
      white-space: nowrap;
      a {
        display: inline-flex;
        background-color: $color-grey-3;
        color: $color-white;
        padding: 4px 12px;
        border-radius: 50px;
        font-size: $font-size-small;
        @media screen and (max-width: 767px) {
          padding: 2px 9px;
        }
      }
      &:first-child {
        @media screen and (max-width: 991px) {
          margin-left: $font-size;
        }
      }
      &:last-child {
        @media screen and (max-width: 991px) {
          margin-right: $font-size;
        }
      }
    }
    .current-menu-item {
      a {
        background-color: $color-secondary;
      }
    }
  }
}
