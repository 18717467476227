.c-blog {
  padding: $space-3 0;
  background-color: $color-background;
  &__content {
    display: flex;
    flex-direction: column;
  }

  &__sidebar {
    .widget-blog {
      &__title {
        display: none;
        visibility: hidden;
      }
      ul {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: $space-01;
        flex-wrap: wrap;
        li {
          a {
            @include button;
            background-color: $color-primary;
            border-color: $color-primary;
            color: $color-black;
            &:hover {
              background-color: $color-primary;
              border-color: $color-primary;
              color: $color-black;
              box-shadow: $box-shadow-small;
              transform: translateY(-2px);
              transition: $trans;
            }
          }
        }
      }
    }
  }

  &__items {
    display: grid;
    grid-template-columns: repeat(3, minmax(0, 1fr));
    gap: $space-4 $space-2;
    grid-auto-rows: 1fr;
    @media screen and (max-width: 991px) {
      grid-template-columns: repeat(2, minmax(0, 1fr));
      gap: $space-2 $space-3;
    }
    @media screen and (max-width: 767px) {
      grid-template-columns: 1fr;
      gap: $space-2;
    }
  }

  &__item {
    .entrada {
      box-shadow: $box-shadow;
      height: 100%;
    }
  }
}

.entrada {
  background-color: $color-white;
  border-radius: $border-radius;
  display: flex;
  flex-direction: column;

  &__link {
  }

  &__image {
    img {
      border-top-left-radius: $border-radius;
      border-top-right-radius: $border-radius;
      aspect-ratio: 4/3;
      width: 100%;
      height: auto;
      object-fit: cover;
    }
  }

  &__info {
    padding: 2rem;
  }

  &__titulo h4 {
    font-size: $header-3;
    font-family: $typo-secondary;
    font-weight: $font-weight-bold;
  }

  &__meta {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: $space-01;
    color: $color-grey-2;
    margin-bottom: $space-1;
    font-size: $font-size-smaller;
  }

  &__tiempo-lectura {
  }

  &__categoria {
    display: flex;
    gap: 0.25rem;
    a {
      color: $color-grey-2;
    }
  }

  &__descripcion {
    p {
      margin-bottom: 0;
    }
    margin-bottom: $space-1;
  }
}

// Single entrada
.s-entrada {
  padding: $space-1 0;
  &__container {
    max-width: 960px;
    margin-left: auto;
    margin-right: auto;
    display: grid;
    grid-template-columns: 70px 1fr 70px;
    gap: $space-2;
    @media screen and (max-width: 991px) {
      grid-template-columns: 40px 1fr;
    }
    @media screen and (max-width: 575px) {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
    }
  }

  &__aside {
  }

  &__aside--left {
  }

  &__content {
  }

  &__thumbnail {
    margin-bottom: $space-01;
    overflow: hidden;
    img {
      width: 100%;
      height: auto;
    }
  }
  .post__cat {
    margin-top: 0;
  }

  &__feat {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: $space-01;
    margin-bottom: $space-1;
  }

  &__feat-item {
    background-color: $color-accent;
    padding: 8px 20px;
    border-radius: 8px;
    color: $color-primary;
    .bi {
    }
  }
}

.share-post {
  position: sticky;
  top: calc($space-4 + $space-01);
  margin-top: $font-size-smaller;
  @media screen and (max-width: 991px) {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-top: 0;
  }
  @media screen and (max-width: 575px) {
    flex-direction: row;
    align-items: center;
  }
  &__item {
    margin-bottom: $font-size;
    @media screen and (max-width: 991px) {
      margin-right: 0.75rem;
      margin-bottom: $space-05;
    }
    a {
      &:hover {
        svg {
          transform: translateY(-2px);
          transition: $trans;
        }
      }
    }
  }

  svg {
    transition: $trans;
    width: 42px;
    height: auto;
    path:first-child {
      fill: $color-primary !important;
    }
  }
}

.relacionadas {
  padding: $space-5 0;
  background-color: $color-grey-6;
  &__content {
    display: grid;
    grid-template-columns: repeat(3, minmax(0, 1fr));
    gap: $space-4 $space-2;
    @media screen and (max-width: 991px) {
      grid-template-columns: repeat(2, minmax(0, 1fr));
      gap: $space-2 $space-3;
    }
    @media screen and (max-width: 767px) {
      grid-template-columns: 1fr;
      gap: $space-2;
    }
  }
}
