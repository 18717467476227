.servicio {
  padding: $space-4 0;
  @media screen and (max-width: 767px) {
    padding: $space-3 0;
  }
  @media screen and (max-width: 480px) {
    padding: $space-2 0;
  }

  &__content {
    display: flex;
    align-items: flex-start;
    gap: $space-4;
    @media screen and (max-width: 991px) {
      flex-direction: column;
      gap: $space-3;
    }
  }

  &__left {
    flex: 0 0 50%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: $space-1;
  }

  &__img {
    img {
      aspect-ratio: 16 / 11;
      width: 100%;
      height: auto;
      object-fit: cover;
    }
  }

  &__right {
  }

  &__info {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: $space-1;
  }

  &__excerpt {
    margin-bottom: $space-2;
    font-size: $header-3;
    font-weight: $font-weight-bold;
    @include after-line;
    @media screen and (max-width: 767px) {
      font-size: $header-3-res;
    }
  }

  &__descripcion {
  }
}

.mas-servicios {
  padding: $space-3 0;
  background-color: $color-grey-6;
  &__items {
    display: grid;
    grid-template-columns: repeat(3, minmax(0, 1fr));
    gap: $space-3;
    grid-auto-rows: 1fr;
    @media screen and (max-width: 991px) {
      grid-template-columns: repeat(2, minmax(0, 1fr));
    }
    @media screen and (max-width: 767px) {
      grid-template-columns: 1fr;
    }
  }

  &__item {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: $space-1;
  }

  &__img {
    img {
      aspect-ratio: 4/3;
      width: 100%;
      height: auto;
      object-fit: cover;
    }
  }

  &__content {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    gap: $space-01;
    height: 100%;
  }

  &__titulo {
    font-size: $header-3;
    font-weight: $font-weight-bold;
    color: $color-secondary;
  }

  &__descripcion {
  }

  &__actions {
    margin-top: auto;
  }
}

.contactos {
  width: 100%;
  &__title {
    font-size: $header-5;
    font-weight: $font-weight-bold;
    margin-bottom: $space-1;
    @media screen and (max-width: 767px) {
      font-size: $header-6;
      margin-bottom: $font-size-small;
    }
  }

  &__actions {
    display: flex;
    gap: $font-size;
    flex-wrap: wrap;
    @media screen and (max-width: 767px) {
      gap: $font-size-smaller;
    }
    .boton {
      flex: 1;
    }
  }
}
