.legal {
  padding: $space-3 0;

  @media screen and (max-width: 480px) {
    padding: $space-2 0;
  }
  &__content {
    // display: grid;
    // grid-template-columns: 1fr 2.5fr;
    // gap: $space-2;
    // @media screen and (max-width: 767px) {
    //   display: flex;
    //   flex-direction: column;
    //   gap: $space-2;
    // }
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  &__sidebar {
    .widget {
      &__title {
        text-transform: uppercase;
        font-family: $typo-secondary;
        color: $color-primary;
      }
      ul.menu {
        list-style: none;
        padding-left: 0;
        margin-bottom: 0;
      }
    }
  }
  &__info {
  }
}
