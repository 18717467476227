.feat-full {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: $space-5;
  @media screen and (max-width: 991px) {
    gap: $space-3;
  }

  &__card {
    width: 100%;
    height: 670px;
    background-color: #ebeae8;
    position: relative;
    overflow: hidden;
    transition: $trans;
    @media screen and (min-width: 1420px) {
      height: 800px;
    }
    @media screen and (max-width: 991px) {
      height: 600px;
      transition: $trans;
    }
    @media screen and (max-width: 767px) {
      height: 460px;
      transition: $trans;
    }
  }

  &__card-overlay {
    position: absolute;
    background: linear-gradient(
      47deg,
      rgba(0, 0, 0, 0.8) 0%,
      rgba(0, 0, 0, 0) 100%
    );
    top: 0;
    bottom: 0;
    width: 100%;
    z-index: 2;
    transition: $trans;

    @media screen and (max-width: 767px) {
      background: rgba($color: #000000, $alpha: 0.6);
      //display: none;
      transition: $trans;
    }
  }

  &__card-img {
    position: absolute;
    top: 0;
    bottom: 0;
    width: 100vw;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    object-fit: cover;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1;
    transition: $trans;
  }

  &__card-container {
    position: relative;
    z-index: 2;
    display: flex;
    height: 100%;
    align-items: flex-end;
    justify-content: flex-start;
    text-align: left;
    transition: $trans;
  }

  &__card-content {
    width: 50%;
    margin-bottom: $space-4;
    transition: $trans;
    @media screen and (max-width: 767px) {
      width: 100%;
      margin-bottom: $space-1;
    }
  }

  &__card-title {
    color: $color-white;
    font-family: $typo-secondary;
    font-weight: $font-weight-bold;
    font-size: $header-1;
    line-height: 1;
    margin-bottom: 0;
    transition: $trans;
    @media screen and (max-width: 575px) {
      font-size: $header-2-res;
      transition: $trans;
    }
  }
  &__card-description {
    margin-top: $space-1;
    color: $color-white;
    font-size: $font-size-big;
  }
}

.feat {
  padding: $space-5 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: $space-5;
  @media screen and (max-width: 991px) {
    gap: $space-3;
  }
  @media screen and (max-width: 767px) {
    padding: $space-3 0;
  }
  @media screen and (max-width: 480px) {
    padding: $space-2 0;
  }

  &__card {
    width: 100%;
    display: flex;
    align-items: center;
    gap: $space-3;
    // @media screen and (max-width: 991px) {
    //   gap: $space-2;
    // }
    @media screen and (max-width: 991px) {
      flex-direction: column;
      gap: $space-1;
    }
    &:nth-child(even) {
      @media screen and (min-width: 992px) {
        flex-direction: row-reverse;
      }
    }
  }

  &__card-img {
    display: block;
    background-color: $color-white;
    box-shadow: $box-shadow-small;
    overflow: hidden;
    flex: 0 0 60%;
    @media screen and (max-width: 991px) {
      flex: 0 0 50%;
    }

    img {
      aspect-ratio: 16 / 11;
      width: 100%;
      height: auto;
      object-fit: cover;
    }
  }

  &__card-content {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    height: max-content;
    transition: $trans;
    // @media screen and (max-width: 991px) {
    //   padding: $space-1;
    //   transition: $trans;
    // }
    &--center {
      align-items: center;
      text-align: center;
    }
  }

  &__card-pretitle {
    text-transform: uppercase;
    font-size: $header-3-res;
    color: $color-primary;
    font-family: $typo-alternative;
    font-weight: $font-weight-bold;
    margin-bottom: $space-05;
    transition: $trans;

    @media screen and (max-width: 767px) {
      display: none;
      transition: $trans;
    }
  }

  &__card-title {
    font-family: $typo-secondary;
    font-size: $header-1;
    font-weight: $font-weight-bold;
    line-height: 1;
    margin-bottom: $font-size;
    color: $color-black;
    margin-bottom: $space-1;
    transition: $trans;

    @media screen and (max-width: 991px) {
      font-size: $header-1-res;
      transition: $trans;
    }
    @media screen and (max-width: 575px) {
      font-size: $header-2;
      transition: $trans;
    }

    @media screen and (max-width: 375px) {
      font-size: $header-2-res;
      transition: $trans;
    }
    a {
      color: $color-black;
      &:hover {
        color: $color-black;
      }
    }
  }

  &__card-description {
    color: $color-text-body;
    line-height: 1.5;
    ul {
      list-style: none;
      padding-left: 0;
      margin-bottom: 0;
      li {
        position: relative;
        padding-left: $space-1;
        &::before {
          position: absolute;
          left: 0;
          top: 3px;
          display: inline-block;
          content: "";
          color: $color-text-body;
          vertical-align: -0.125em;
          background-image: url("data:image/svg+xml,<svg viewBox='0 0 16 16' fill='%23333' xmlns='http://www.w3.org/2000/svg'><path fill-rule='evenodd' d='M4 8a.5.5 0 0 1 .5-.5h5.793L8.146 5.354a.5.5 0 1 1 .708-.708l3 3a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708-.708L10.293 8.5H4.5A.5.5 0 0 1 4 8z' clip-rule='evenodd'/></svg>");
          background-repeat: no-repeat;
          background-size: 1rem 1rem;
          width: 1rem;
          height: 1rem;
          // filter: brightness(0) invert(1);
        }
      }
    }
  }

  &__card-actions {
    margin-top: $space-01;
  }
}
