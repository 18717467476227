.cta {
  background-size: cover;
  padding: 5rem 0;
  background-color: $color-background;
  position: relative;

  @media screen and (max-width: 991px) {
    padding: 6rem 0;
  }
  @media screen and (max-width: 575px) {
    padding: 3rem 0;
  }

  // &__overlay {
  //   position: absolute;
  //   top: 0;
  //   bottom: 0;
  //   width: 100%;
  //   background: rgba($color: #000000, $alpha: 0.6);
  //   @media screen and (max-width: 767px) {
  //     background: rgba($color: #000000, $alpha: 0.5);
  //   }
  // }

  &__container {
    position: relative;
    display: flex;
    height: 100%;
    align-items: center;
    justify-content: center;
    @media screen and (max-width: 575px) {
      align-items: flex-end;
    }
  }

  &__content {
    text-align: center;
  }

  &__titulo {
    font-weight: bold;
    font-size: $header-1;
    margin-bottom: 1.5rem;
    color: $color-black;
    @media screen and (max-width: 1199px) {
      font-size: $header-1;
    }
    @media screen and (max-width: 767px) {
      font-size: $header-2;
    }
  }

  &__actions {
    display: flex;
    flex-wrap: wrap;
    gap: 2rem;
    justify-content: center;
    @media screen and (max-width: 767px) {
      margin-top: 1rem;
    }
    @media screen and (max-width: 575px) {
      gap: 1rem;
    }
    .boton {
      @media screen and (max-width: 575px) {
        width: 100%;
        text-align: center;
        justify-content: center;
      }
    }
  }
}
