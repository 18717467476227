.contacto {
  padding: $space-5 0;
  @media screen and (max-width: 767px) {
    padding: $space-3 0;
  }
  @media screen and (max-width: 480px) {
    padding: $space-2 0;
  }

  &__descripcion {
    margin-bottom: $space-3;
    line-height: 1.2;
    transition: $trans;

    @media screen and (max-width: 991px) {
      margin-bottom: $space-01;
      transition: $trans;
    }
  }

  &__content {
    //color: $color-white;
    display: grid;
    grid-template-columns: 1fr 2fr;
    gap: $space-4;
    transition: $trans;

    @media screen and (max-width: 1199px) {
      grid-template-columns: 1fr 1.5fr;
      transition: $trans;
    }
    @media screen and (max-width: 991px) {
      display: flex;
      flex-direction: column;
      transition: $trans;
      gap: $space-2;
    }
  }

  &__col1 {
  }

  &__contenido {
    @media screen and (max-width: 991px) {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      flex-wrap: wrap;
      gap: $space-1;
      transition: $trans;
    }
    @media screen and (max-width: 767px) {
      flex-direction: column;
      justify-content: flex-start;
      gap: $space-01;
      transition: $trans;
    }
  }

  &__col2 {
    display: flex;
    flex-direction: column;
    //color: $color-white;
  }

  &__direccion {
    margin-bottom: $space-01;
  }
  &__horario {
    display: flex;
    flex-direction: column;
    margin-bottom: $space-01;
    @media screen and (max-width: 480px) {
      margin-bottom: 0;
      p {
        margin-bottom: 0;
      }
    }
  }

  &__telefonos {
    display: flex;
    flex-direction: column;
    margin-bottom: $space-01;
    font-size: $font-size;
    @media screen and (max-width: 991px) {
      margin-bottom: $space-01;
    }
    a {
      color: $color-black !important;
      text-decoration: none;
      &:hover {
        color: $color-primary !important;
      }
    }
    strong {
      margin-bottom: 0.25rem;
    }
  }

  &__telefonos-item {
    display: flex;
    margin-bottom: 0.2rem;
    .bi {
      margin-right: 0.5rem;
    }
    a {
      color: $color-black;
      &:hover {
        color: $color-primary;
      }
    }
    &:nth-last-child(1) {
      margin-top: 0.25rem;
      margin-bottom: 0;
    }
  }

  &__social {
    margin-top: $space-1;
    p {
      display: none;
    }
    .widget-footer__title {
      color: $color-black;
      font-size: $font-size-bigger;
      font-weight: $font-weight-bold;
    }
    // .zoom-social-icons-list {
    //   margin-left: 0 !important;
    //   display: flex;
    //   justify-content: flex-start;
    // }
    // .social-icon {
    //   font-size: 28px !important;
    //   color: $color-black !important;
    //   background-color: rgba($color-white, 0.3) !important;
    //   padding: 3px !important;
    //   @media screen and (max-width: 767px) {
    //     font-size: 22px !important;
    //   }
    //   &:hover {
    //     color: $color-primary !important;
    //     background-color: rgba($color-white, 0.5) !important;
    //   }
    // }
  }
}

.ubicacion {
  background-color: $color-grey-6;
  iframe {
    width: 100%;
  }
}
