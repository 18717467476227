.page-title {
  padding: $space-1 0 $space-3 0;
  background-color: $color-background-ptitle;
  @media screen and (max-width: 991px) {
    padding-bottom: $space-2;
  }
  @media screen and (max-width: 575px) {
    padding-top: 4.5rem;
    padding-bottom: 1rem;
  }

  &__h {
    margin-bottom: 0rem;
    font-size: $header-1;
    font-weight: bold;
    color: $color-white;
    transition: $trans;
    @media screen and (max-width: 991px) {
      font-size: $header-1-res;
      transition: $trans;
    }
    @media screen and (max-width: 575px) {
      font-size: $header-2;
      transition: $trans;
    }

    @media screen and (max-width: 375px) {
      font-size: $header-2-res;
      transition: $trans;
    }
  }
  &__h2 {
    font-size: $header-3;
    font-weight: bold;
    color: $color-white;
    margin-top: $space-05;
    transition: $trans;
    @media screen and (max-width: 575px) {
      font-size: $header-3-res;
      transition: $trans;
    }
  }
  &__breadcrumbs {
    // margin-bottom: calc($font-size / 2);
    font-size: $font-size-small;
    color: $color-white;
    margin-bottom: $space-5;
    @media screen and (max-width: 991px) {
      margin-bottom: $space-4;
    }
    @media screen and (max-width: 767px) {
      margin-bottom: $space-3;
    }
    @media screen and (max-width: 575px) {
      font-size: $font-size-smaller;
      // margin-bottom: 5px;
    }
    a {
      font-family: $typo-secondary;
      color: $color-white;
      &:hover {
        color: $color-primary;
      }
    }
  }
}

.page-title--post {
  text-align: center;
}
