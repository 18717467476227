.footer {
  padding: $space-3 0 $space-3 0;
  background-color: $color-background-footer;
  box-shadow: 0 50vh 0 50vh $color-background-footer;
  @media screen and (max-width: 767px) {
    padding-bottom: $space-2;
  }
  &__content {
    display: grid;
    grid-template-columns: repeat(4, minmax(0, 1fr));
    gap: $space-3;
    @media screen and (max-width: 991px) {
      grid-template-columns: repeat(3, minmax(0, 1fr));
      gap: $space-2 $space-3;
    }
    @media screen and (max-width: 767px) {
      grid-template-columns: 1fr;
      gap: $space-2;
    }
  }

  &__item {
    color: $color-text-footer;
    p {
      line-height: 1.5;
    }
    a {
      color: $color-text-footer;
      &:hover {
        color: $color-text-footer-hover;
      }
    }
    .widget-footer__title {
      color: $color-text-footer;
      font-size: 22px;
      font-weight: $font-weight-bold;
      text-transform: uppercase;
      @media screen and (max-width: 991px) {
        font-size: 18px;
      }
    }
  }

  &__item--logo {
    @media screen and (min-width: 768px) and (max-width: 991px) {
      grid-column: 1 / span 3;
    }
    img,
    svg {
      max-width: 240px;
    }
  }
}

.widget-footer.zoom-social-icons-widget {
  ul {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-left: 0px;
    margin-top: -5px;
    @media screen and (max-width: 767px) {
      margin-left: 0 !important;
      margin-top: 0 !important;
      gap: $space-05;
    }
    // li:nth-child(1) {
    //   .social-icon {
    //     padding-left: 0 !important;
    //   }
    // }
  }
  .social-icon {
    font-size: 22px !important;
    padding: 10px !important;
    background-color: $color-text-footer !important;
    color: $color-background-footer !important;
    @media screen and (max-width: 800px) {
      font-size: 16px !important;
    }
    &:hover {
      background-color: $color-text-footer-hover !important;
      color: $color-background-footer !important;
    }
  }
}

.copyright {
  padding: 0 0 $space-1 0;
  position: relative;
  &__content {
  }
  &__copy {
    color: $color-text-footer;
    font-size: $font-size-small;
  }
  &__privacy {
    a {
      color: $color-text-footer;
      &:hover {
        color: $color-text-footer-hover;
      }
    }
  }
}

.next-gen {
  padding-bottom: $space-1;
  background-color: $color-white;

  @media screen and (max-width: 575px) {
    padding-bottom: $space-1;
  }
  &__content {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: $space-1 $space-1;
    flex-wrap: wrap;
    // flex-direction: column;
    padding-top: $space-1;
    border-top: 1px dashed $color-white;
    @media screen and (max-width: 575px) {
      display: flex;
      flex-direction: column;
      gap: calc($font-size / 2);
    }
  }

  &__logos {
    display: flex;
    gap: $space-1;
  }
  &__img {
    img {
      width: 180px;
      height: auto;
      @media screen and (max-width: 575px) {
        width: 100px;
      }
    }
  }

  &__description {
    color: $color-dark;
    font-size: $font-size-smaller;
    @media screen and (max-width: 575px) {
      text-align: center;
    }
  }
}
