.slick-center {
}

.slick-track {
  margin-bottom: $space-1 !important;
}

.slick-dots li {
  position: relative !important;
  display: inline-block !important;
  width: 20px !important;
  height: 20px !important;
  margin: 0 5px !important;
  padding: 0 !important;
  cursor: pointer !important;
  @media screen and (max-width: 575px) {
    margin-left: 2px !important;
    margin-right: 2px !important;
  }
}

.slick-dots li.slick-active button::before {
  opacity: 0.75 !important;
  color: $color-grey-2 !important;
}

.slick-dots li button::before {
  font-size: 2rem !important;
  opacity: 0.25 !important;
  color: $color-grey-2 !important;
  @media screen and (max-width: 767px) {
    font-size: calc($font-size * 2) !important;
  }
}

.slick-prev,
.slick-next {
  top: 43% !important;
  background: transparent !important;
  @media screen and (max-width: 575px) {
    display: none !important;
  }
}

.slick-prev {
  left: -2rem !important;
}

.slick-next {
  right: -2rem !important;
}

.slick-prev:before,
.slick-next:before {
  font-family: "Font Awesome 6 Free" !important;
  font-weight: 900 !important;
  font-size: 20px !important;
  color: $color-grey-3 !important;
}

.slick-prev:before,
[dir="rtl"] .slick-next:before {
  content: "\f104" !important;
}

.slick-next:before,
[dir="rtl"] .slick-prev:before {
  content: "\f105" !important;
}
