.hero {
  height: 770px;
  background-color: #ebeae8;
  position: relative;
  transition: $trans;
  @media screen and (max-width: 991px) {
    height: 680px;
    transition: $trans;
  }

  @media screen and (max-width: 575px) {
    height: 50vh;
    transition: $trans;
  }

  &__overlay {
    position: absolute;
    background: rgba($color: #000000, $alpha: 0.3);
    top: 0;
    bottom: 0;
    width: 100%;
    z-index: 2;
    transition: $trans;

    @media screen and (max-width: 767px) {
      background: rgba($color: #000000, $alpha: 0.3);
      //display: none;
      transition: $trans;
    }
  }

  &__bg {
    top: 0;
    bottom: 0;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    position: absolute;
    z-index: 1;
    transition: $trans;

    @media screen and (min-width: 1419px) {
      // max-width: 1419px;
      margin-left: auto;
      margin-right: auto;
      transition: $trans;
    }
    @media screen and (min-width: 576px) {
      display: none;
    }
  }

  &__video {
    position: absolute;
    top: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    object-fit: cover;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1;
    transition: $trans;

    @media screen and (min-width: 1419px) {
      // max-width: 1419px;
      margin-left: auto;
      margin-right: auto;
      transition: $trans;
    }

    // @media screen and (max-width: 575px) {
    //   display: none;
    // }
  }

  &__container {
    position: relative;
    z-index: 2;
    display: flex;
    height: 100%;
    align-items: flex-end;
    justify-content: flex-start;
    transition: $trans;
    @media screen and (max-width: 575px) {
      align-items: flex-end;
      transition: $trans;
    }
  }
  &__content {
    margin-bottom: $space-5;
    max-width: 520px;
    @media screen and (max-width: 767px) {
      margin-bottom: $space-2;
    }
  }

  &__pretitulo {
    color: $color-white;
    font-weight: $font-weight-bold;
    font-size: $header-5;
    margin-bottom: $font-size-smaller;
    transition: $trans;
    letter-spacing: 1px;
    text-transform: uppercase;
  }

  &__titulo {
    color: $color-white;
    font-weight: $font-weight-bold;
    font-size: $display-2;
    line-height: 1;
    margin-bottom: $space-01;
    transition: $trans;
    @media screen and (max-width: 767px) {
      font-size: $display-3;
      transition: $trans;
    }
    @media screen and (max-width: 400px) {
      font-size: $header-1;
    }
  }
  &__descripcion {
    color: $color-white;
    border-radius: 3px;
    font-weight: 500;
    margin-bottom: $space-2;
    font-size: $font-size-bigger;
    transition: $trans;

    @media screen and (max-width: 991px) {
      margin-bottom: 1rem;
      transition: $trans;
    }
    @media screen and (max-width: 767px) {
      font-size: $font-size;
      margin-bottom: 1rem;
      transition: $trans;
    }
  }

  &__actions {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: $space-1;
    @media screen and (max-width: 767px) {
      gap: $font-size;
    }
  }
}
