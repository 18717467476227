$color-primary: #b92521;
$color-primary-light: lighten($color-primary, 10);
$color-primary-dark: darken($color-primary, 10);

$color-secondary: #040404;
$color-secondary-light: lighten($color-secondary, 10);
$color-secondary-dark: darken($color-secondary, 10);

$color-accent: #000;
$color-accent-light: #000;
$color-accent-dark: #000;

$color-white: #fff;
$color-black: #000;

$color-grey-1: #262626;
$color-grey-2: #424142;
$color-grey-3: #7f7f7f;
$color-grey-4: #b6b6b6;
$color-grey-5: #d7d7d7;
$color-grey-6: #f5f5f5;

$color-dark: #1e0028;

$color-background: $color-white;
$color-background-2: #f4f8fd;

$color-background-ptitle: #1b1b1b;

$color-overlay: #030303;
$color-fondo-hero: #030303;

$color-whatsapp-dark: #128c7e;
$color-whatsapp-light: #25d366;

$color-background-footer: $color-grey-6;
$color-text-footer: $color-black;
$color-text-footer-hover: $color-primary;

$color-text-body: $color-grey-2;
$color-text-muted: $color-grey-4;

$font-size-smaller: 0.75rem;
$font-size-small: 0.9rem;
$font-size: 1rem;
$font-size-big: 1.125rem;
$font-size-bigger: 1.25rem;

$font-weight-light: 200;
$font-weight-normal: 400;
$font-weight-medium: 600;
$font-weight-bold: 700;
$font-weight-bolder: 800;

$link: $color-accent;

$box-shadow-small: 0px 0px 15px rgba(0, 0, 0, 0.1);
$box-shadow: 0px 0px 30px #00000019 !important;
$box-shadow-large: 0px 16px 48px rgba(30, 0, 40, 0.1);
$box-shadow-hover: 0 0 20px 0 rgba(0, 0, 0, 0.05);

$border-radius-min: 0.375rem;
$border-radius: 1.3125rem;
$border-radius-cta: 2.5rem;
$border-radius-big: 25px;

$trans: 0.2s all ease-in-out;
$trans-fast: 0.1s all ease-in-out;
$trans-3: 0.3s all ease-in-out;
$trans-5: 0.5s all ease-in-out;
$trans-4: 1s all ease-in-out;

$header-1: 3rem;
$header-1-res: 2.5rem;
$header-2: 2.25rem;
$header-2-res: 1.875rem;
$header-3: 24px;
$header-3-res: 1.25rem;
$header-4: 1.25rem;
$header-5: 1rem;
$header-6: 0.87rem;

$display-extra-large: 8.375rem;
$display-1: 6rem;
$display-2: 4.35rem;
$display-3: 3.45rem;
$display-4: 2.18rem;

$space-0: 0px;
$space-05: 0.5rem;
$space-01: 1rem;
$space-1: 1.5rem;
$space-02: 2rem;
$space-2: 2.5rem;
$space-3: 4rem;
$space-4: 5rem;
$space-45: 112px;
$space-5: 7.5rem;
$space-6: 9.375rem;

$space-nav: 1.25rem;

$typo-primary: "Work Sans", sans-serif;
$typo-secondary: "Work Sans", sans-serif;
$typo-alternative: "Work Sans", sans-serif;
