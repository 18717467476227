.header {
  padding: $space-1 0;
  background-color: $color-background;
  width: 100%;
  z-index: 99;
  position: sticky;
  top: 0;
  transition: $trans;
  @media screen and (max-width: 991px) {
    padding: $space-01 0;
  }

  &--sticky {
    padding: 0.75rem 0;
    box-shadow: $box-shadow;
    transition: $trans;
    .header__logo {
      img {
        width: 200px !important;
        transition: $trans;
        @media screen and (max-width: 991px) {
          width: 160px !important;
          transition: $trans;
        }
        @media screen and (max-width: 575px) {
          width: 140px !important;
          transition: $trans;
        }
      }
    }
  }

  &__content {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    // @media screen and (max-width: 991px) {
    //   display: grid;
    //   grid-template-columns: 46px 1fr 46px;
    // }
  }

  &__logo {
    img {
      width: 220px;
      height: auto;
      transition: $trans;
      @media screen and (max-width: 991px) {
        width: 180px;
        // justify-self: center;
        // display: flex;
        // justify-content: center;
        transition: $trans;
      }
      @media screen and (max-width: 420px) {
        width: 150px;
        transition: $trans;
      }
    }
  }

  &__nav {
    @media screen and (max-width: 767px) {
      display: none;
    }
    &--left {
      margin-left: $space-3;
      @media screen and (max-width: 991px) {
        margin-left: auto;
      }
    }
    &--right {
      margin-left: auto;
      @media screen and (max-width: 991px) {
        margin-left: $space-1;
      }
    }
  }

  .bi {
    color: $color-secondary;
    font-size: 1.5rem;
    transition: $trans;
    line-height: 1;
    &:hover {
      color: $color-primary;
      transition: $trans;
    }
  }

  &__burger {
    margin-left: auto;
    .bi {
      font-size: 2rem;
    }
    @media screen and (max-width: 767px) {
      justify-self: flex-start;
    }
    @media screen and (min-width: 768px) {
      display: none;
    }
  }
}
