@mixin button {
  padding: 14px 24px;
  font-size: 1rem;
  border-radius: 15px;
  font-family: $typo-secondary;
  line-height: 1;
  text-align: center;
  cursor: pointer;
  border: 0;
  transition: $trans;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  border-width: 1px;
  border-style: solid;
  &--lg {
    padding: 19px 3rem;
    font-size: $font-size-bigger;
    @media screen and (max-width: 767px) {
      padding: 1rem 1.475rem;
    }
  }
  &--sm {
    padding: 7px 13px;
    font-size: $font-size-small;
  }
}

@mixin card-yellow($padding-card) {
  background-color: rgba($color: $color-accent, $alpha: 0.1);
  padding: $padding-card;
  border-radius: 5px;
  border: 2px dashed lighten($color-accent, 30%);
}

@mixin img-rounded {
  box-shadow: $box-shadow;
  border-radius: $border-radius;
}

@mixin after-line {
  padding-bottom: $font-size;
  position: relative;
  &::after {
    content: "";
    width: 100px;
    background-color: $color-primary;
    height: 2px;
    position: absolute;
    bottom: 0;
    left: 0;
  }
}
